import React, { useState } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';
import VARIABLES from 'src/consts/variables';

import EosioLogoSmallSVG from 'src/assets/EosioLogoSmallSVG';
import Search from 'src/svg/search.inline.svg';

import HamburgerButton from './components/HamburgerButton';
import PrimaryNavigationBar from './components/PrimaryNavigationBar';
import AlgoliaSearchBar from 'src/components/AlgoliaSearchBar';

const StyledEosioLogoSmallSVG = styled(EosioLogoSmallSVG)`
  width: auto;
  height: 48px;
  display: none;
  margin-top: -24px;

  ${MEDIA_QUERIES.TABLET} {
    display: block;
    height: 41px;
    margin-top: 1px;
    vertical-align: middle;
    body.browser-ie11 &{
      width: 36px;
    }
  }
`;

const Wrapper = styled.div`
  position: fixed;
  background: white;
  border-bottom: 1px solid #f0efef;
  font-weight: 500;
  z-index: 1;
  width: calc(100% - 389px);

  ${MEDIA_QUERIES.TABLET} {
    width: 100vw;
    border-bottom: 1px solid #000000;
  }

  ${MEDIA_QUERIES.MOBILE} {
    width: 100vw;
    border-bottom: 1px solid #000000;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1566px;
  height: ${VARIABLES.HEIGHT_HEADER_DESKTOP - 1}px;

  ${MEDIA_QUERIES._4K_ONLY} {
    max-width: 3523px;
  }

  ${MEDIA_QUERIES.TABLET} {
    padding: 0 30px;
  }

  ${MEDIA_QUERIES.MOBILE} {
    padding: 0 0 0 22px;
    height: ${VARIABLES.HEIGHT_HEADER_MOBILE - 1}px;
  }

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-left: auto;
  flex: 1;

  ${MEDIA_QUERIES._4K_ONLY} {
    margin-left: 420px;
  }

  ${MEDIA_QUERIES._2K_ONLY} {
    margin-left: 245px;
  }

  ${MEDIA_QUERIES.DESKTOP} {
    margin-right: -120px;
  }

  ${MEDIA_QUERIES.LAPTOP} {
    margin-right: -20px;
  }

  ${MEDIA_QUERIES.TABLET} {
    justify-content: flex-end;
    margin-right: 0;
  }

  ${MEDIA_QUERIES.MOBILE} {
    margin-right: 0;
  }

  .ButtonHamburger {
    display: none;
    ${MEDIA_QUERIES.TABLET} {
      display: inline-block;
    }
  }

  .SearchWrapper {
    display: none;
    ${MEDIA_QUERIES.TABLET} {
      display: inline-block;
    }
  }

  button {
    width: 29px;
    height: 29px;
    cursor: pointer;
    margin-right: 30px;

    display: none;

    ${MEDIA_QUERIES.TABLET} {
      display: inline-block;
    }

    svg {
      fill: #000000;
    }
  }
`

const HeaderTail = styled.div`
  flex: 0.001;
  ${MEDIA_QUERIES.TABLET} {
    display: none;
  }
`

const Header = (props) => {
  const { primaryNavData, isMenuOpen, toggleHamburgerMenu, showSearchState = false, currentQuery = null } = props;
  const [ showSearch, toggleShowSearch ] = useState(showSearchState);

  return (
    <Wrapper className="Header">
      <Container>
        <PrimaryNavigationBar
            className="header-only"
            primaryNavData={primaryNavData}
        />
        <StyledEosioLogoSmallSVG />
        <Inner>  
          {
            showSearch
            ? <div className="SearchWrapper">
                <AlgoliaSearchBar currentQuery={currentQuery} onClearHandler={() => toggleShowSearch(false)} />
              </div>
            : <button onClick={() => toggleShowSearch(true)}>
                <Search id="trigger-search-btn"/>
              </button>
          }
          <HamburgerButton
            className={`${isMenuOpen ? "isMenuOpen" : ""}`}
            onIconClick={() => {toggleHamburgerMenu();}}
          />
        </Inner>
        <HeaderTail/>
      </Container>
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { navigate } = state;
  return {
    isMenuOpen: navigate.isMenuOpen,
  };
}

const mapDispatchToProps =  dispatch => {
  return { toggleHamburgerMenu: () => dispatch({ type: `NAV_TOGGLE_HAMBURGER_MENU` }) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
