/*
  variables for UI
*/

export default {
  HEIGHT_HEADER_DESKTOP : 61,
  HEIGHT_HEADER_MOBILE : 61,
  WIDTH_SIDEBAR_DESKTOP : 306,
  WIDTH_SIDEBAR_LAPTOP : 240,
  WIDTH_TABLE_OF_CONTENTS_DESKTOP : 250,
  WIDTH_TABLE_OF_CONTENTS_LAPTOP : 190
}
