import React, { Component } from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

const Navbar = styled.div`
  color: #111a44;
  margin-left: 3.9em;
  line-height: 1.4;
  flex: 1;
  font-size: 16px;

  &.isMenuOpen{
    ${MEDIA_QUERIES.TABLET_ONLY} {
      display: none;
    }
  }  

  ${MEDIA_QUERIES.HD_MIN} {
    margin-left: 3.9em;  
    width: auto;
  }

  ${MEDIA_QUERIES.DESKTOP} {
    font-size: 15px;
    margin-left: 3.8em;  
    width: auto;
  }

  ${MEDIA_QUERIES.LAPTOP} {
    margin-left: 2.0em;
    font-size: 13px;
    width: 500px;
  }
  
  ${MEDIA_QUERIES.TABLET_ONLY} {
    display: none;
  }
  
  ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`

const NavigationList = styled.ul`
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  li {
    display: inline-block;
    margin-right: 31px;

    ${MEDIA_QUERIES.DESKTOP} {
      margin-right: 25px;
    }

    ${MEDIA_QUERIES.LAPTOP} {
      margin-right: 12px;
      text-align: center;
    }

  }
`

const PrimaryNavigationLink = props => {
  let { path, text } = props.link;

  if (path !== "-1")
    return (path.startsWith('http')) ? 
      <a href={path}>{text}</a>
    : <Link to={path} activeStyle={{fontWeight:700, color: "#0F4FE3"}}>{text}</Link>
  else 
    return <button style={{cursor:'pointer'}} onClick={ev => window.history.go(-1)}>
      {text}
    </button>
}

class PrimaryNavigationBar extends Component {
  render () {
    let { primaryNavData } = this.props;

    return (
      <Navbar>
        <NavigationList>
          {
            (primaryNavData.length > 0) &&
            primaryNavData.map(link => 
              link.text === "NONE" ? null :
              <li key={link.path}>
                <PrimaryNavigationLink link={link}/>
              </li>)
          }
        </NavigationList>
      </Navbar>
    )
  }
}

export default PrimaryNavigationBar;
