const interpolateString = function (string, props) {
  let { orgName, repoName, rawTag } = props;
  return string.replace(/{org}|{repo}|{tag}/gi, (term) => {
    switch(term) {
      case "{org}":
        return orgName;
      case "{repo}":
        return repoName;
      case "{tag}":
        return rawTag;
      default:
        return term;
    }
  })
}

export default interpolateString;
