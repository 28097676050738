import React  from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';
import VARIABLES from 'src/consts/variables';

import EosioLogoSmallSVG from 'src/assets/EosioLogoSmallSVG';
import PrimaryNavigationBar from './components/PrimaryNavigationBar';
import AlgoliaSearchBar from 'src/components/AlgoliaSearchBar';

const StyledEosioLogoSmallSVG = styled(EosioLogoSmallSVG)`
  width: auto;
  //height: 60px;
  display: none;
  margin-top: -24px;

  ${MEDIA_QUERIES.TABLET} {
    display: block;
    height: 41px;
    margin-top: 1px;
    vertical-align: middle;
    body.browser-ie11 &{
      width: 36px;
    }
  }
`;

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  background: white;
  border-bottom: 1px solid #f0efef;
  font-weight: 500;
  z-index: 150;

  ${MEDIA_QUERIES.LAPTOP} {
    width: 100vw;
  }

  ${MEDIA_QUERIES.TABLET} {
    width: 100vw;
    border-bottom: 1px solid #000000;
  }

  ${MEDIA_QUERIES.MOBILE} {
    width: 100vw;
    border-bottom: 1px solid #000000;
  }
`;

const StyledEosioLogoRedocSVG = styled(EosioLogoSmallSVG)`
  position: fixed;
  left: 25px;
  top: 13px;
  width: auto;
  height: 36px;
  display: block;
  margin-left: 9px;

  ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }

  ${MEDIA_QUERIES.TABLET} {
    display: none;
  }
`;

const DeveloperText = styled.div`
  position: fixed;
  left: 73px;
  top: 19px;
  height: 36px;
  font-size: 20px;
  color: #121944

  ${MEDIA_QUERIES.DESKTOP} {
    display: none;
  }

  ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }

  ${MEDIA_QUERIES.TABLET} {
    display: none;
  }
`;

const Container = styled.div`
  margin: 0 0 0 371px;
  max-width: 1566px;
  height: ${VARIABLES.HEIGHT_HEADER_DESKTOP - 1}px;

  ${MEDIA_QUERIES._4K_ONLY} {
    max-width: 3523px;
  }

  ${MEDIA_QUERIES.LAPTOP} {
    margin: 0 0 0 345px;
  }

  ${MEDIA_QUERIES.TABLET} {
    margin: 0 auto;
    padding: 0 30px;
  }

  ${MEDIA_QUERIES.MOBILE} {
    margin: 0 auto;
    padding: 0 0 0 22px;
    height: ${VARIABLES.HEIGHT_HEADER_MOBILE - 1}px;
  }

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-left: auto;
  flex: 1;

  .SearchWrapper {
    display: inline-block;

    ${MEDIA_QUERIES.TABLET} {
      margin-bottom: 25px;

      .SearchForm {
        button {
          top: 7px;
          right: 170px;
        }
        div {
          top: 7px;
          right: 60px;
        }
      }
    }
  }

  ${MEDIA_QUERIES._4K_ONLY} {
    margin-left: 420px;
  }

  ${MEDIA_QUERIES._2K_ONLY} {
    margin-left: 245px;
  }

  ${MEDIA_QUERIES.LAPTOP} {
    margin-right: -20px;
  }

  ${MEDIA_QUERIES.TABLET} {
    justify-content: flex-end;
    margin-right: 0;
  }

  ${MEDIA_QUERIES.MOBILE} {
    margin-right: 0;
  }
`

const HamburgerMenuText = styled.span`
  display: none;

  &.isMenuOpen {
    display: none;
  }

  ${MEDIA_QUERIES.TABLET} {
    display: none;
    font-size: 13px;
    font-weight: 600;
    margin-right: 28px;
  }

  ${MEDIA_QUERIES.MOBILE} {
    margin-right: 10px;
    display: block;
  }
`

const HeaderTail = styled.div`
  flex: 0.001;
  ${MEDIA_QUERIES.TABLET} {
    display: none;
  }
`

const Header = (props) => {
  let { primaryNavData } = props;

  return (
    <Wrapper className="Header">
      <Container>
        <Link to="/">
          <StyledEosioLogoRedocSVG />
          {/*<DeveloperText>developers</DeveloperText>*/}
        </Link>
        {
          <PrimaryNavigationBar
            className="header-only"
            primaryNavData={primaryNavData}
            />
        }
        <StyledEosioLogoSmallSVG />
        <Inner>            
          <div className="SearchWrapper">
            <AlgoliaSearchBar />
          </div>
          <HamburgerMenuText>
            <Link to="/">Home</Link>
          </HamburgerMenuText>
        </Inner>
        <HeaderTail />
      </Container>
    </Wrapper>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { navigate } = state;
  return {
    isMenuOpen: navigate.isMenuOpen,
  };
}

const mapDispatchToProps =  dispatch => {
  return { toggleHamburgerMenu: () => dispatch({ type: `NAV_TOGGLE_HAMBURGER_MENU` }) }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
