import "./App.scss";

import React from "react";
import { Helmet } from 'react-helmet';
import interpolateString from 'src/actions/string-interpolator';

// import favicon from "src/assets/favicon.ico";
import favicon32 from "src/assets/cropped-favicon-1-32x32.png";
import favicon192 from "src/assets/cropped-favicon-1-192x192.png";
import favicon180 from "src/assets/cropped-favicon-1-180x180.png";
import ogImage from "src/assets/og_dev_portal.png";

class App extends React.Component {

  render(){
    let { 
      children, orgName, repoName, currentVersion, contentTitle, canonicalVersion,
      isTagged, frontmatter = {}, information = {} 
    } = this.props;
    let { page_title = "", page_description = "" } = frontmatter;
    let { title = "" } = information;
    if (contentTitle !== "") {
      contentTitle += " | "
    }  
    let boilerplateDefaultDesc = `Developer Portal for EOSIO. EOSIO is a next-generation, open-source blockchain protocol with industry-leading transaction speed and flexible utility.`;
    //let boilerplateDefaultTitle = `${contentTitle} ${repoName} | ${orgName}`;
    let ogTitle = `EOSIO Developer Portal - EOSIO Development Documentation`;
    let pageMetaTitle = "";
    let pageMetaDescription = "";

    if (Object.keys(frontmatter).length > 0) {
      pageMetaTitle = (page_title) ? interpolateString(page_title, this.props) : ogTitle;
      pageMetaDescription = (page_description) ? interpolateString(page_description, this.props) : boilerplateDefaultDesc
    } else {
      pageMetaTitle = (title) ? `${title} | API Specification` : ogTitle;
      pageMetaDescription = boilerplateDefaultDesc;
    }
    
    if (!isTagged)
    return (
      <div className="App">
        <Helmet
          titleTemplate="%s | EOSIO Developer Docs"
          defaultTitle="EOSIO Developer Docs"
        >
          <html lang="en" />

          <title>{pageMetaTitle}</title>
          <meta charSet="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,shrink-to-fit=no,viewport-fit=cover"
          />
          <meta name="HandheldFriendly" content="true" />

          <meta name="description" content={pageMetaDescription} />

          <meta property="og:title" content={pageMetaTitle} />
          <meta property="og:description" content={pageMetaDescription} />
          <meta property="og:image" content={"https://developers.eos.io" + ogImage} />
          
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@block_one_" />
          <meta name="twitter:title" content={pageMetaTitle} />
          <meta name="twitter:description" content={pageMetaDescription} />
          <meta name="twitter:image" content={"https://developers.eos.io" + ogImage} />

          <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
          <link rel="icon" type="image/png" href={favicon192} sizes="192x192" />
          <link rel="apple-touch-icon" type="image/png" href={favicon180} />

          {
            (currentVersion === 'latest' && typeof window !== 'undefined' && isTagged) &&
            <link rel="canonical" href={
              window.location.href.replace('latest',canonicalVersion)
            }/>
          }

          <script type="text/javascript">{`setTimeout(() => window.dataLayer.push({ 'organization': '${orgName}', 'repository': '${repoName}', 'version': '${currentVersion}' }), 50);`}</script>
        </Helmet>
        {children}
      </div>    )
    else
    return (
      <div className="App">
        <Helmet
          titleTemplate="%s | EOSIO Developer Docs"
          defaultTitle="EOSIO Developer Docs"
        >
          <html lang="en" />

          <title>{pageMetaTitle}</title>
          <meta charSet="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,maximum-scale=1.0,shrink-to-fit=no,viewport-fit=cover,user-scalable=no"
          />
          <meta name="HandheldFriendly" content="true" />

          <meta name="description" content={pageMetaDescription} />

          <meta property="og:title" content={pageMetaTitle} />
          <meta property="og:description" content={pageMetaDescription} />
          <meta property="og:image" content={"https://developers.eos.io" + ogImage} />
          
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@block_one_" />
          <meta name="twitter:title" content={pageMetaTitle} />
          <meta name="twitter:description" content={pageMetaDescription} />
          <meta name="twitter:image" content={"https://developers.eos.io" + ogImage} />

          <link rel="icon" type="image/png" href={favicon32} sizes="32x32" />
          <link rel="icon" type="image/png" href={favicon192} sizes="192x192" />
          <link rel="apple-touch-icon" type="image/png" href={favicon180} />

          {
            (currentVersion === 'latest' && typeof window !== 'undefined' && isTagged) &&
            <link rel="canonical" href={
              window.location.href.replace('latest',canonicalVersion)
            }/>
          }

          <script type="text/javascript">{`setTimeout(() => window.dataLayer.push({ 'organization': '${orgName}', 'repository': '${repoName}', 'version': '${currentVersion}' }), 50);`}</script>
        </Helmet>
        {children}
      </div>
    )
  }

}

export default App
