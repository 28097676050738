import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import Search from 'src/svg/search.inline.svg';
import Clear from 'src/svg/clear.inline.svg';

import './style.scss';

const AlgoliaSearchBar = (props) => {
  const { onClearHandler = null, className = '', currentQuery = '' } = props;
  const [query, setQuery] = useState(currentQuery);

  const onInputChange = (e) => {
    e.persist();
    setQuery(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${query}`, {replace: false});
  }

  useEffect(() => {
    setQuery(currentQuery)
  }, [currentQuery])

  return (
    <div className={`AlgoliaMenuSearchBar ${className}`}>
      <form className="SearchForm" onSubmit={onSubmit}>
        <input id="search-input"
          type="text"
          placeholder="Search"
          aria-label="Search"
          value={query}
          onChange={onInputChange}
          required 
          />
        <button type="submit">
          <Search id="search-btn"/>
        </button>
        {query && query !== '' && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              setQuery('');
              if (onClearHandler) {
                onClearHandler();
              }
            }}
            onKeyDown={() => {
              setQuery('');
              if (onClearHandler) {
                onClearHandler();
              }
            }}
          >
            <Clear />
          </div>
        )}
      </form>
    </div>
  )
}

export default AlgoliaSearchBar;
